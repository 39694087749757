.rounded-dialog .MuiPaper-root {
	box-sizing: border-box !important;
	border-radius: 2em !important;
	background-color: #f7f7f7 !important;
	width: 25%;
	position:absolute;
}

.rounded-dialog [role="dialog"] {
	border-radius: 2em !important;
	background-color: #f7f7f7 !important;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	position:absolute;
}