@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@200;400;600;700&display=swap);
* {
  margin: 0;
  padding: 0;
  font-family: Roboto, "Segoe UI", "Source Serif Pro", Tahoma, Geneva, Verdana,
    sans-serif;
}

html,
body {
  height: 100%;
  overflow: hidden;
  margin: 0px;
}

#root {
  height: 100%;
}

*:not(input) {
  -webkit-user-select: none;
  user-select: none;
}

/* .MuiTabs-flexContainer {
  justify-content: space-between;
} */

.MuiDataGrid-row.Mui-odd {
  background-color: #e0e0e0;
}

.MuiDataGrid-row:hover {
  cursor: pointer;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  outline: none !important;
  border-bottom: none !important;
}

.MuiDataGrid-root .MuiDataGrid-colCell:focus {
  outline: none !important;
}

.MuiFormHelperText-root {
  max-width: 400px;
}

.button-style-1 {
  background-color: #ecbd2a !important;
  border: 2px solid #ecbd2a !important;
  color: #000 !important;
  border-radius: 0 !important;
  padding: 0.5em 1.5em !important;
  box-sizing: border-box;
  letter-spacing: 0.15em !important;
  min-width: 120px;
}

.button-style-1__outline {
  background-color: #f7f7f7 !important;
  color: #000 !important;
  border-radius: 0 !important;
  border: 2px solid #ecbd2a !important;
  box-sizing: border-box;
  padding: 0.5em 1.5em !important;
  letter-spacing: 0.15em !important;
  min-width: 120px;
}

.button-style-1__outline:hover {
  background-color: #f7f7f7 !important;
  color: #000 !important;
  border-radius: 0 !important;
  border: 2px solid #ecbd2a !important;
  box-sizing: border-box;
}

.button-style-1__outline2 {
  background-color: #f7f7f7 !important;
  color: #000 !important;
  border-radius: 0 !important;
  border: 2px solid #ecbd2a !important;
  box-sizing: border-box;
  padding: 0.5em 0.5em !important;
  letter-spacing: 0.15em !important;
  width: 120;
}

.button-style-1__outline2:hover {
  background-color: #f7f7f7 !important;
  color: #000 !important;
  border-radius: 0 !important;
  border: 2px solid #ecbd2a !important;
  box-sizing: border-box;
}

.button-style-1:hover {
  background-color: #ecbd2a !important;
  border: 2px solid #ecbd2a !important;
}

.button-style-2 {
  background-color: #ecbd2a !important;
  color: black !important;
  border-radius: 0 !important;
  letter-spacing: 0.15em !important;
}

.dialog-style-1 {
  z-index: 995;
  left: 0 !important;
  width: 480px !important;
  height: 230px !important;
  align-items: center;
}

.dialog-narration-style {
  left: 0 !important;
  width: 420px !important;
  height: 170px !important;
  overflow: hidden !important;
}
.messDialogWrapper {
  position: relative;
  overflow: hidden !important;
}
@media only screen and (max-width: 1024px) {
  .MuiDialog-container-1051 {
    width: 50%;
    -webkit-transform: translate(50%, 0);
            transform: translate(50%, 0);
    top: 0;
    left: 0;
  }
  .dialog-style-1 {
    overflow: hidden !important;
    font-size: 0.7em !important;
    width: 300px !important;
  }
}
@media only screen and (max-width: 768px) {
  .dialog-style-1 {
    /* background-color: blueviolet; */
    width: 270px !important;
    font-size: 0.6em !important;
  }
}
@media only screen and (max-width: 1920px) {
  .dialog-narration-style {
    left: 0 !important;
    width: 340px !important;
    height: 155px !important;
    overflow: hidden !important;
  }
}

@media only screen and (max-width: 1600px) {
  .dialog-narration-style {
    left: 0 !important;
    width: 330px !important;
    height: 155px !important;
    overflow: hidden !important;
    font-size: 1em;
  }
}

@media only screen and (max-width: 1440px) {
  .dialog-narration-style {
    left: 0 !important;
    width: 310px !important;
    height: 150px !important;
    overflow: hidden !important;
  }
}

@media only screen and (max-width: 1360px) {
  .dialog-narration-style {
    left: 0 !important;
    width: 280px !important;
    height: 145px !important;
    overflow: hidden !important;
  }
}

@media only screen and (max-width: 1280px) {
  .dialog-narration-style {
    left: 0 !important;
    width: 255px !important;
    height: 140px !important;
    overflow: hidden !important;
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 1024px) {
  .dialog-narration-style {
    left: 0 !important;
    width: 245px !important;
    height: 130px !important;
    overflow: hidden !important;
    font-size: 0.4em;
  }
}

@media only screen and (max-width: 768px) {
  .dialog-narration-style {
    left: 0 !important;
    width: 230px !important;
    height: 110px !important;
    overflow: hidden !important;
    font-size: 0.4em;
  }
}

.button-style-1:hover {
  background-color: #ffbe08 !important;
}

.color-1 {
  color: #63baea !important;
}

.color-2 {
  color: #ecbd2a !important;
}

.button-style-black {
  background-color: #000 !important;
  border: 2px solid #000 !important;
  color: #fff !important;
  border-radius: 0 !important;
  padding: 0.5em 1.5em !important;
  box-sizing: border-box;
  letter-spacing: 0.15em !important;
}

.button-style-black:hover {
  background-color: #000 !important;
  border: 2px solid #000 !important;
}
.words-separate-btn-ok {
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  width: 90%;
}

.MuiFormLabel-root.MuiInputLabel-shrink {
  color: white;
}

.MuiFormLabel-root.Mui-focused {
  color: white !important;
}

@media only screen and (max-width: 1024px) {
  .MuiDialog-root {
    right: 20%;
  }
}

.glow-on-hover {
  position: relative;
  z-index: 0;
  border-radius: 20px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  -webkit-filter: blur(5px);
          filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  -webkit-animation: glowing 20s linear infinite;
          animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 99%;
  height: 100%;
  background: white;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@-webkit-keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

#mess-dialog {
  z-index: 1200 !important;
}
.confetti-img {
  width: 100%;
  height: 40%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99;
  -webkit-animation: confettiimg 5s;
          animation: confettiimg 5s;
}

canvas#confetti {
  width: 100%;
  height: 100%;
}

div#win-game-bg {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 0%,
    rgba(14, 14, 14, 0.521) 100%
  );
}

div#capture-btn {
  position: absolute;
  z-index: 2;
}

button#capture-btn {
  right: 3%;
}

svg.MuiSvgIcon-root {
  font-size: 16px;
}

img#star-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

button.btn-win-game {
  position: absolute;
  right: -16px;
  top: -16px;
  width: 32px;
  height: 32px;
  background: #f3ba11;
  z-index: 10;
  border-radius: 999px;
  border: none;
  transition: all 0.3s ease-in-out;
}

button.btn-win-game:hover {
  background: #ac8505;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@-webkit-keyframes confetti {
  0% {
    top: 10%;
  }
  100% {
    top: 37%;
  }
}

@keyframes confetti {
  0% {
    top: 10%;
  }
  100% {
    top: 37%;
  }
}
@-webkit-keyframes confettiimg {
  0% {
    /* transform: scale(0); */
    width: 0%;
    height: 0%;
  }
  100% {
    /* transform: scale(1); */
    width: 85%;
    height: 40%;
  }
}
@keyframes confettiimg {
  0% {
    /* transform: scale(0); */
    width: 0%;
    height: 0%;
  }
  100% {
    /* transform: scale(1); */
    width: 85%;
    height: 40%;
  }
}

.confetti-bg {
  -webkit-animation: confettiimg;
          animation: confettiimg;
}
section {
  display: block;
  width: 100%;
  height: 100%;
  left: 70%;
  right: 30%;
  top: 50%;
  bottom: 50%;
  -webkit-perspective: 500;
}
section span {
  width: 80%;
  height: 80%;
  bottom: 50px;
  background: transparent;
  position: absolute;
  border-radius: 50%;
  margin: auto;
  pointer-events: none;
  z-index: 99;
  -webkit-animation: animatebubble 5s infinite;
          animation: animatebubble 5s infinite;
  -webkit-animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
          animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  -webkit-filter: blur(3px);
          filter: blur(3px);
}
section span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0.25) translate(-70%, -70%);
          transform: scale(0.25) translate(-70%, -70%);
  background: radial-gradient(#fff, transparent);
  border-radius: 50%;
}
@-webkit-keyframes animatebubble {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    left: 60%;
    right: 40%;
    /* top: 30%; */
    bottom: 60%;
  }
  /* 25% {
    transform: translateZ(100px);
    right: 30%;
    bottom: 80%;
  } */
  50% {
    -webkit-transform: translateZ(200px);
            transform: translateZ(200px);
    /* right: 40%; */
    bottom: 70%;
    /* bottom:  */
  }
  75% {
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    bottom: 60%;
    /* left: 40%; */
  }
  850% {
    bottom: 40%;
    /* transform: translateZ(600px); */
  }
  100% {
    -webkit-transform: translateZ(400px);
            transform: translateZ(400px);
    bottom: 20%;
  }
}
@keyframes animatebubble {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    left: 60%;
    right: 40%;
    /* top: 30%; */
    bottom: 60%;
  }
  /* 25% {
    transform: translateZ(100px);
    right: 30%;
    bottom: 80%;
  } */
  50% {
    -webkit-transform: translateZ(200px);
            transform: translateZ(200px);
    /* right: 40%; */
    bottom: 70%;
    /* bottom:  */
  }
  75% {
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    bottom: 60%;
    /* left: 40%; */
  }
  850% {
    bottom: 40%;
    /* transform: translateZ(600px); */
  }
  100% {
    -webkit-transform: translateZ(400px);
            transform: translateZ(400px);
    bottom: 20%;
  }
}

.background-hourGlass {
  background: linear-gradient(
    90deg,
    rgba(185, 170, 9, 1) 16%,
    rgba(224, 218, 43, 1) 51%,
    rgba(0, 212, 255, 1) 100%
  );
}
.background-hourGlass:hover {
  cursor: pointer;
}
.MuiToolbar-gutters {
  padding: 0 !important;
}
::-webkit-input-placeholder {
  color: #541513 !important;
  opacity: 1 !important; /* Firefox */
}
::placeholder {
  color: #541513 !important;
  opacity: 1 !important; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #541513 !important;
  opacity: 1 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #541513 !important;
  opacity: 1 !important;
}

.MuiInputBase-root {
  height: 100%;
}

.MuiButton-root {
  min-width: 0px !important;
}

.Muibutton-starticon {
  margin: 0 !important;
}

.MuiOutlinedInput-root {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
::-ms-reveal {
  display: none;
}

/* custom toastify over-playtime */
.toastify--over-playtime {
  position: absolute;
  bottom: 130px;
}

.rounded-dialog .MuiPaper-root {
	box-sizing: border-box !important;
	border-radius: 2em !important;
	background-color: #f7f7f7 !important;
	width: 25%;
	position:absolute;
}

.rounded-dialog [role="dialog"] {
	border-radius: 2em !important;
	background-color: #f7f7f7 !important;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	position:absolute;
}
.base-dialog .MuiPaper-root {
   border-radius: 1em 1em 2em 2em !important;
   background-color: #f7f7f7 !important;
}
